import React, { useState, useEffect, useContext } from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import { motion } from "framer-motion";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { Swiper, SwiperSlide } from "swiper/react";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { MdOutlineClose, MdShoppingBasket } from "react-icons/md";
import ModalPhoto from "../components/product/ModalPhoto";
import MetaTag from "../components/metaTag";
import {
  toggleButtonSx,
  toggleContainerSx,
  textFieldSx,
  selectListSx,
  formSx,
  inputLabelSx,
  menuItemSx,
} from "../components/product/mui.style";
import {
  previewContainer,
  previewImage,
  settingsElement,
  toggle,
  image,
} from "../style/pages/sticks.module.scss";
import {
  detail,
  settingsContainer,
  titleSettings,
  priceInfo,
  mobCloseBtn,
  closeBtn,
  costBundle,
  detailHeader,
  settings,
  settingsBlock,
  productInfo,
  settingsList,
  orderInfo,
  productContainer,
  productItem,
  delProduct,
  mobileProducts,
  hint,
  nubmer,
} from "../components/product/product.module.scss";

import dataJson from "../data/sticks.json";

import { StoreContext } from "../context/store-context";

function Sticks() {
  const imageData = useStaticQuery(graphql`
    {
      allFile(
        filter: { relativeDirectory: { eq: "sticks" }, name: { nin: ["main"] } }
        sort: { order: ASC, fields: name }
      ) {
        nodes {
          childImageSharp {
            id
            gatsbyImageData(
              breakpoints: [750, 1366, 1920]
              formats: [AUTO, AVIF, WEBP]
              quality: 85
              placeholder: DOMINANT_COLOR
            )
          }
        }
      }
    }
  `);

  const [selectedImg, setSelectedImg] = useState(null);

  const { store, setStore } = useContext(StoreContext);
  const [totalPrice, setTotalPrice] = useState(0);

  const [sticks, setSticks] = useState("");
  const [color, setColor] = useState(null);
  const [needEngraving, setNeedEngraving] = useState(false);
  const [engravingText, setEngravingText] = useState("");
  const [cost, setCost] = useState(0);
  const [quantity, setQuantity] = useState(0);

  const [totalAmount, setTotalAmount] = useState(0);

  const [mobileList, setMobileList] = useState(false);

  // Выбор палочки для эскимо
  const handleStick = (event) => {
    setSticks(event.target.value);
    dataJson
      .filter((item) => item.info === event.target.value)
      .forEach((item) => setNeedEngraving(item.needEngraving));
    setEngravingText("");
    setColor(null);
    setCost(0);
  };

  // Обновить состояние для цвета
  const handleColor = (event, newAlignment) => {
    setColor(newAlignment);
  };

  // Обновить состояние для текста гравировки
  const handleEngraving = (event) => {
    setEngravingText(event.target.value);
  };

  // Обновить состояние для количества топпера
  const handleQuantity = (event) => {
    if (Number(event.target.value) >= 0) {
      setQuantity(Number(event.target.value));
    } else return;
  };

  // Добавить первый набор опор
  const addProduts = () => {
    const dataOrder = {
      sticks: sticks,
      color: color,
      needEngraving: needEngraving,
      engravingText: engravingText,
      quantity: quantity,
      totalAmount: totalAmount,
    };

    setTotalPrice(totalPrice + dataOrder.totalAmount);

    setStore({
      ...store,
      totalPrice: store.totalPrice + dataOrder.totalAmount,
      substrates: { ...store.substrates },
      screens: { ...store.screens },
      spatulas: { ...store.spatulas },
      tag: { ...store.tag },
      toppers: { ...store.toppers },
      stand: { ...store.stand },
      frame: { ...store.frame },
      acrylicfalsebeads: { ...store.acrylicfalsebeads },
      sticks: {
        bundle: [...store.sticks.bundle, dataOrder],
        totalPrice: store.sticks.totalPrice + dataOrder.totalAmount,
      },
    });

    setSticks("");
    setColor(null);
    setNeedEngraving(false);
    setEngravingText("");
    setQuantity(0);
    setTotalAmount(0);
  };

  // Удалить набор подложек
  const deleteProduct = (index) => {
    // Массив всех наборов
    const arrBundle = [...store.sticks.bundle];
    // Цена набора, который нужно удалить
    const deductibleAmount = store.sticks.bundle[index].totalAmount;

    // Удаляем из массива наборов элемент с заданным index
    arrBundle.splice(index, 1);
    setStore({
      ...store,
      totalPrice: store.totalPrice - deductibleAmount,
      sticks: {
        bundle: [...arrBundle],
        totalPrice: store.sticks.totalPrice - deductibleAmount,
      },
      acrylicfalsebeads: { ...store.acrylicfalsebeads },
      frame: { ...store.frame },
      screens: { ...store.screens },
      substrates: { ...store.substrates },
      spatulas: { ...store.spatulas },
      tag: { ...store.tag },
      toppers: { ...store.toppers },
      stand: { ...store.stand },
    });
  };

  useEffect(() => {
    dataJson
      .filter((item) => item.info === sticks)
      .map((item) => setCost(Number(item.price)));
    let q = quantity || 0;
    let c = cost || 0;

    if (
      (!needEngraving && !engravingText) ||
      (needEngraving && engravingText)
    ) {
      setTotalAmount(Math.ceil(q * c));
    } else {
      setTotalAmount(0);
    }
  }, [quantity, sticks, cost, engravingText, needEngraving]);

  return (
    <main className="mainpage">
      <MetaTag title="Палочки для эскимо | Wowmilota" />
      <motion.div
        layoutId="sticksContainer"
        transition={{ type: "spring", bounce: 0.05, mass: 0.1 }}
        className={detail}
      >
        {selectedImg && (
          <ModalPhoto
            selectedImg={selectedImg}
            setSelectedImg={setSelectedImg}
          />
        )}

        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.4 }}
          className={previewContainer}
        >
          {imageData?.allFile?.nodes.map((img, index) => {
            const { childImageSharp } = img;
            const src = getImage(img);

            return (
              <motion.div
                onClick={() => setSelectedImg(src)}
                onKeyDown={() => setSelectedImg(src)}
                className={previewImage}
                key={childImageSharp.id}
                whileHover={{ scale: 0.98 }}
              >
                <GatsbyImage
                  image={src}
                  alt="Фотография подложки из фанеры"
                  className={image}
                />
              </motion.div>
            );
          })}
        </motion.div>

        <div className={settingsContainer}>
          <div className={settings}>
            <div className={settingsBlock}>
              <div className={productInfo}>
                <motion.div
                  className={detailHeader}
                  layout="position"
                  layoutId="sticksTitle"
                >
                  <h2>Палочки для эскимо</h2>
                </motion.div>
                <p>
                  Палочки для пирожных "Эскимо" из акрила.
                  <br />
                  Изготовлены из экологичного материала, допустимого в пищевом
                  производстве.
                  <br />
                  С нашими палочками ваш десерт приобретет шикарный
                  блистательный вид, подчеркнет стиль и статусность.
                  <br />
                  А нанесение гравировки с вашим лого или контактами станет
                  дополнительной рекламой вашей кондитерской студии.
                  <br /> <br />
                  Размер стандарт 10*1см
                  <br />
                  Размер Mini 8*0,9см
                </p>
              </div>

              <h3 className={titleSettings}>
                Выберите набор палочек для эскимо
              </h3>

              <div className={settingsList}>
                <div
                  className={settingsElement}
                  style={{
                    backgroundColor: sticks
                      ? "rgba(255, 255, 255, 0.15)"
                      : "rgba(255, 255, 255, 0.05)",
                  }}
                >
                  <h3>Наборы палочек для эскимо</h3>
                  <FormControl variant="standard" fullWidth sx={formSx}>
                    <InputLabel id="sticks" sx={inputLabelSx}>
                      Выберите набор палочек
                    </InputLabel>
                    <Select
                      sx={selectListSx}
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={sticks}
                      label="Выберите топпер"
                      onChange={handleStick}
                    >
                      {dataJson.map((item, index) => {
                        const { info } = item;

                        return (
                          <MenuItem
                            sx={menuItemSx}
                            value={info}
                            key={`topper${index}`}
                          >
                            {info}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </div>

                <div
                  className={settingsElement}
                  style={{
                    backgroundColor: color
                      ? "rgba(255, 255, 255, 0.15)"
                      : "rgba(255, 255, 255, 0.05)",
                  }}
                >
                  <h3>Цвет</h3>
                  {!sticks && <p>Сначала выберите набор</p>}
                  <ToggleButtonGroup
                    size="medium"
                    value={color}
                    exclusive
                    onChange={handleColor}
                    sx={toggleContainerSx}
                  >
                    {dataJson
                      .filter((item) => item.info === sticks)
                      .map((item) => {
                        const { color } = item;

                        return color.map((item) => (
                          <ToggleButton
                            value={item}
                            key={`color${item}`}
                            sx={toggleButtonSx}
                          >
                            {item}
                          </ToggleButton>
                        ));
                      })}
                  </ToggleButtonGroup>
                </div>

                {needEngraving && (
                  <div
                    className={settingsElement}
                    style={{
                      backgroundColor: engravingText
                        ? "rgba(255, 255, 255, 0.15)"
                        : "rgba(255, 255, 255, 0.05)",
                    }}
                  >
                    <h3>Гравировка</h3>
                    <div className={toggle}>
                      <TextField
                        fullWidth
                        id="sticksEngravingText"
                        label="Текст гравировки"
                        variant="standard"
                        value={engravingText}
                        onChange={handleEngraving}
                        sx={textFieldSx}
                      />
                    </div>
                  </div>
                )}

                <div
                  className={settingsElement}
                  style={{
                    backgroundColor:
                      quantity > 0
                        ? "rgba(255, 255, 255, 0.15)"
                        : "rgba(255, 255, 255, 0.05)",
                  }}
                >
                  <h3>Количество</h3>
                  <div className={toggle}>
                    <TextField
                      id="sticksQuantity"
                      variant="standard"
                      type="number"
                      onFocus={(e) => e.target.value === "0" && setQuantity("")}
                      onChange={handleQuantity}
                      value={quantity}
                      sx={textFieldSx}
                      inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className={orderInfo}>
              <h3>Ваши наборы</h3>
              {store?.sticks.totalPrice > 0 ? (
                <div className={productContainer}>
                  {store?.sticks.bundle.map((item, index) => {
                    return (
                      <div key={`order_${index}`} className={productItem}>
                        <button
                          className={delProduct}
                          aria-label="Удалить"
                          onClick={() => deleteProduct(index)}
                        >
                          <MdOutlineClose color="white" />
                        </button>
                        <h3>Набор {index + 1}</h3>
                        <p>{item.sticks}</p>
                        <p>{item.color}</p>
                        {item.needEngraving && (
                          <p>Гравировка: {item.engravingText}</p>
                        )}
                        <p>{item.quantity} шт.</p>
                        <p>{item.totalAmount} руб.</p>
                      </div>
                    );
                  })}
                </div>
              ) : (
                <div className={hint}>
                  <p>
                    Пока у Вас нет наборов.
                    <br />
                    <br />
                    Когда Вы выберите все обязательные параметры, кнопка внизу
                    покажет стоимость набора.
                    <br /> <br />
                    Вы можете формировать любое количество наборов, просто
                    продолжайте выбирать параметры.
                  </p>
                </div>
              )}
            </div>

            <div className={priceInfo}>
              <div className={mobileProducts}>
                <button onClick={() => setMobileList(!mobileList)}>
                  <MdShoppingBasket color="white" />
                  <p className={nubmer}>{store?.sticks.bundle.length}</p>
                </button>
                {mobileList && (
                  <Swiper
                    slidesPerView={"auto"}
                    spaceBetween={10}
                    grabCursor={true}
                    className={productContainer}
                  >
                    {store?.sticks.bundle.map((item, index) => {
                      return (
                        <SwiperSlide
                          key={`order_${index}`}
                          className={productItem}
                        >
                          <button
                            className="delProduct"
                            aria-label="Удалить"
                            onClick={() => deleteProduct(index)}
                          >
                            <MdOutlineClose color="white" />
                          </button>
                          <h3>Набор {index + 1}</h3>
                          <p>{item.sticks}</p>
                          <p>{item.color}</p>
                          {item.needEngraving && (
                            <p>Гравировка: {item.engravingText}</p>
                          )}
                          <p>{item.quantity} шт.</p>
                          <p>{item.totalAmount} руб.</p>
                        </SwiperSlide>
                      );
                    })}
                  </Swiper>
                )}
              </div>
              {totalAmount > 0 && color ? (
                <button className={costBundle} onClick={addProduts}>
                  {totalAmount > 0
                    ? `Добавить набор ${totalAmount} руб.`
                    : "Сформируйте набор"}
                </button>
              ) : (
                <p className={costBundle}>Сформируйте набор</p>
              )}
              <Link to="/" className={mobCloseBtn}>
                <MdOutlineClose color="white" />
              </Link>
              <Link to="/" className={closeBtn}>
                {store?.sticks.bundle.length.length > 0
                  ? `Сохранить и закрыть`
                  : `закрыть`}
              </Link>
            </div>
          </div>
        </div>
      </motion.div>
    </main>
  );
}

export default Sticks;
